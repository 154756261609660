import { createSlice, isAnyOf, type PayloadAction } from '@reduxjs/toolkit';
import { appsApi, TApp } from 'entities/apps';

import { Edge, MarkerType, Node } from 'reactflow';
import { TActionNode, TErrorFields } from './types';
import { DISABLED_ACTION_TYPE } from './constants';
import { STYLES } from '../../../shared';

export type TAppState = Omit<TApp, 'actions' | 'oauthClients' | 'approximateCost'> & {
	actions: TActionNode[];
	edges: Edge[];
	currentTab: 'settings' | 'steps';
	hasSettingsError?: boolean;
	editorDisabled: boolean;
	isActionRemoved: boolean;
	oauthClients: string[];
};

const initialState: TAppState = {
	id: null,
	name: null,
	isFavourite: false,
	isPrivate: true,
	actions: [],
	edges: [],
	currentTab: 'steps',
	editorDisabled: false,
	isActionRemoved: false,
	oauthClients: []
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setEdges: (state, action: PayloadAction<Edge[]>) => {
			state.edges = [...action.payload];
		},
		resetApp: () => initialState,
		updateEmoji: (state, action: PayloadAction<string>) => {
			state.emoji = action.payload;
		},
		setIsActionListChanged: (state, action: PayloadAction<boolean>) => {
			state.isActionRemoved = action.payload;
		},
		setActions: (state, action: PayloadAction<Node[]>) => {
			const selectedNode = action.payload.find((node) => node.selected);
			state.actions = [...action.payload];
			state.edges = state.edges.map((edge) => {
				if (edge.source === selectedNode?.id) {
					return {
						...edge,
						markerEnd: {
							type: MarkerType.ArrowClosed,
							color: STYLES.colors.primary,
							width: 16,
							height: 16
						},
						style: {
							stroke: STYLES.colors.primary,
							strokeWidth: 2
						}
					};
				} else {
					return {
						...edge,
						markerEnd: {
							type: MarkerType.ArrowClosed,
							color: STYLES.colors.gray_light
						},
						style: {
							stroke: STYLES.colors.gray_light,
							strokeWidth: 2
						}
					};
				}
			});
		},
		setCurrentTab: (state, action: PayloadAction<'settings' | 'steps'>) => {
			state.currentTab = action.payload;
		},
		addEmptyItemToArray: (
			state,
			action: PayloadAction<{ actionId: string; key: string; item: Record<string, any> }>
		) => {
			const { actionId, key, item } = action.payload;
			const targetArray = state.actions.find((action) => action.id === actionId)?.data[key];
			if (Array.isArray(targetArray)) {
				targetArray.push(item);
			}
		},
		removeArrayItem: (state, action: PayloadAction<{ actionId: string; key: string; itemIndex: number }>) => {
			const { actionId, key, itemIndex } = action.payload;
			const targetArray = state.actions.find((action) => action.id === actionId)?.data[key];
			if (Array.isArray(targetArray)) {
				targetArray.splice(itemIndex, 1);
			}
		},
		changeActionType: (state, action: PayloadAction<{ id: string; newType: string }>) => {
			const { id, newType } = action.payload;
			state.actions = state.actions.map((action) => {
				if (action.id === id) {
					return {
						...action,
						draggable: true,
						deletable: true,
						type: newType,
						data: {
							...action.data,
							blockType: newType,
							isHidden: true,
							...(newType === 'gpt_call'
								? { contentConversionType: 'notConverted', model: 'gpt-4o', temperature: '0.5' }
								: {}),
							...(newType === 'llm_call' ? { contentConversionType: 'notConverted', temperature: '0.7' } : {}),
							...(newType === 'jsonata_call' ? { expression: '$' } : {}),
							...(newType === 'mailing_call' ? { types: { telegram: true, email: true } } : {})
						}
					};
				} else {
					return action;
				}
			});
		},
		updateActionData: (state, action: PayloadAction<{ actionId: string; path: string; value: any }>) => {
			const { actionId, path, value } = action.payload;
			const pathArray = path.split('.');
			pathArray.splice(0, 2);

			if (pathArray[pathArray.length - 1] === 'slug') {
				const current = state.actions.find((action) => action.id === actionId);
				const incomersAction = state.actions.find((action) => action.data.nextSlug === actionId);
				if (incomersAction && current) {
					incomersAction.data.nextSlug = value;
					current.id = value;
					current.data.slug = value;
				}
				const edges: Edge[] = [];
				state.actions.forEach((node) => {
					if (node.data.nextSlug) {
						edges.push({
							id: `${node.id}->${node.data.nextSlug}`,
							source: node.id,
							target: node.data.nextSlug,
							type: 'customedge',
							markerEnd: {
								type: MarkerType.ArrowClosed,
								color: STYLES.colors.gray_light
							},
							style: {
								stroke: STYLES.colors.gray_light,
								strokeWidth: 2
							}
						});
					}
				});
				state.edges = edges;
			} else {
				let current = state.actions.find((action) => action.id === actionId)?.data;
				for (let i = 0; i < pathArray.length - 1; i++) {
					const pathItem = pathArray[i];
					if (pathItem && current) {
						current = current[pathItem];
					}
				}
				const targetKey = pathArray[pathArray.length - 1];
				if (targetKey && current) {
					current[targetKey] = value;
				}
			}
		},
		setErrors: (state, action: PayloadAction<TErrorFields[]>) => {
			const { payload } = action;
			state.hasSettingsError = !!payload.find((field) => field.name.length === 1);
			const errorActionIndexes: number[] = [];
			payload.forEach((field) => {
				if (typeof field.name[1] === 'string') {
					errorActionIndexes.push(parseInt(field.name[1], 10));
				}
			});
			state.actions = state.actions.map((action, index) => {
				if (errorActionIndexes.indexOf(index) !== -1) {
					return {
						...action,
						data: { ...action.data, status: 'error' }
					};
				} else {
					return {
						...action,
						data: { ...action.data, status: 'success' }
					};
				}
			});
		},
		resetErrors: (state) => {
			state.hasSettingsError = false;
			state.actions = state.actions.map((action) => ({
				...action,
				data: { ...action.data, status: 'success' }
			}));
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(appsApi.endpoints.getAppById.matchFulfilled, (state, action) => {
			let editorDisabled = false;
			const actions = action.payload.actions?.length
				? action.payload.actions
						?.filter((action) => {
							if (DISABLED_ACTION_TYPE.includes(action.blockType)) {
								editorDisabled = true;
							}
							return !DISABLED_ACTION_TYPE.includes(action.blockType);
						})
						.map((action, index) => ({
							id: action.slug,
							position: action.nodeProps?.position
								? { x: action.nodeProps?.position.x, y: action.nodeProps?.position.y }
								: { x: 0, y: 150 * (index + 1) },
							selected: false,
							draggable: true,
							deletable: !DISABLED_ACTION_TYPE.includes(action.blockType),
							width: 300,
							height: 80,
							zIndex: 0,
							type: action.blockType,
							data: {
								...action,
								isHidden: !action.isHidden,
								type: action.type ?? 'static_text'
							}
						}))
				: [];

			const nodeList = [
				{
					id: 'idea',
					position: { x: 0, y: 50 },
					selected: true,
					draggable: false,
					deletable: false,
					width: 300,
					height: 80,
					zIndex: 0,
					type: 'idea',
					data: {
						blockType: 'idea',
						name: 'Старт',
						slug: 'idea',
						placeholder: action.payload.placeholder,
						nextSlug: action.payload.actions?.length ? action.payload.actions[0]?.slug : undefined
					}
				},
				...actions
			];
			const edges: Edge[] = [];
			nodeList.forEach((node) => {
				if (node.data.nextSlug) {
					edges.push({
						id: `${node.id}->${node.data.nextSlug}`,
						source: node.id,
						target: node.data.nextSlug,
						type: 'customedge',
						markerEnd: {
							type: MarkerType.ArrowClosed,
							color: STYLES.colors.gray_light
						},
						style: {
							stroke: STYLES.colors.gray_light,
							strokeWidth: 2
						}
					});
				}
			});
			return {
				...action.payload,
				oauthClients: action.payload?.oauthClients.map((client) => client.id),
				editorDisabled: editorDisabled,
				actions: nodeList,
				currentTab: 'steps',
				isActionRemoved: false,
				edges
			};
		});
	}
});

export const {
	setEdges,
	addEmptyItemToArray,
	removeArrayItem,
	setActions,
	changeActionType,
	setErrors,
	resetErrors,
	setCurrentTab,
	updateActionData,
	setIsActionListChanged,
	updateEmoji,
	resetApp
} = appSlice.actions;
