import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { selectCurrentTab, selectActions } from '../../model/selectors';
import { Form, FormInstance, ConfigProvider } from 'antd';
import { updateActionData } from '../../model/slice';
import { useTranslate } from 'shared/lib/i18n';
import cx from './EditAppForm.module.css';
import { OnNodesDelete } from 'reactflow';
import { methodDebounce } from 'shared/lib';
import { AppSettingsFormItems } from '../AppSettingsFormItems';
import { AppFormFields } from '../AppFormFields';
import { DeleteActionButton } from '../DeleteActionButton';

interface IEditAppFormProps {
	onNodesDelete: OnNodesDelete;
	form: FormInstance;
	onFinish: () => void;
}

const EditAppFormComponent = ({ form, onFinish, onNodesDelete }: IEditAppFormProps) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const actions = useAppSelector(selectActions);
	const currentTab = useAppSelector(selectCurrentTab);
	const [editedActionKey, setEditedActionKey] = useState<string>('');

	useEffect(() => {
		if (editedActionKey === 'slug') {
			form.resetFields();
		}
	}, [editedActionKey, actions]);

	const handleFieldChange = (actionId: string, path: string[], value: any) => {
		const fullPath = path.join('.');
		dispatch(updateActionData({ actionId, path: fullPath, value }));
		const targetKey = path[path.length - 1];
		if (targetKey) {
			setEditedActionKey(targetKey);
		}
	};

	const debouncedHandleFieldChange: (...args: Parameters<typeof handleFieldChange>) => void = useCallback(
		methodDebounce((actionId: string, path: string[], value: any) => handleFieldChange(actionId, path, value), 300),
		[handleFieldChange]
	);

	return (
		<div className='px-5 py-6 overflow-auto mt-[57px]'>
			<ConfigProvider
				theme={{
					components: {
						Form: {
							verticalLabelPadding: 0
						}
					}
				}}
			>
				<Form layout='vertical' onFinish={onFinish} form={form} className={cx.root} requiredMark={false}>
					<AppSettingsFormItems isActive={currentTab === 'settings'} form={form} />
					<div className={currentTab === 'steps' ? '' : 'hidden'}>
						{actions?.map((action, index) => {
							switch (action.type) {
								case 'idea':
									return (
										<AppFormFields.Idea
											key={`${action.id}_${index}`}
											action={action}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
										/>
									);
								case 'gpt_call':
									return (
										<AppFormFields.GptCall
											key={`${action.id}_${index}`}
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											title={t('gpt_call')}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								case 'jsonata_call':
									return (
										<AppFormFields.JsonataCall
											key={`${action.id}_${index}`}
											title='JSONATA'
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								case 'llm_call':
									return (
										<AppFormFields.LlmCall
											key={`${action.id}_${index}`}
											title='LLM'
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								case 'mailing_call':
									return (
										<AppFormFields.MailingCall
											key={`${action.id}_${index}`}
											title={t('mailing_call')}
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								case 'api_call':
									return (
										<AppFormFields.ApiCall
											key={`${action.id}_${index}`}
											title={t('api_call')}
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								case 'api_template_call':
									return (
										<AppFormFields.ApiTemplateCall
											key={`${action.id}_${index}`}
											title={t('api_template_call')}
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								case 'vault_call':
									return (
										<AppFormFields.VaultCall
											key={`${action.id}_${index}`}
											title={t('vault_call')}
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								case 'task_call':
									return (
										<AppFormFields.TaskCall
											key={`${action.id}_${index}`}
											title={t('task_call')}
											action={action}
											form={form}
											path={['actions', String(index)]}
											actionIndex={index}
											onChange={(path: string[], value: any) => debouncedHandleFieldChange(action.id, path, value)}
											actionsSlot={<DeleteActionButton action={action} onNodesDelete={onNodesDelete} />}
										/>
									);
								default:
									return <div key={`${action.id}_${index}`}>{t('select_step_type')}</div>;
							}
						})}
					</div>
				</Form>
			</ConfigProvider>
		</div>
	);
};

export const EditAppForm = memo(EditAppFormComponent);
