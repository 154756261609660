import { PROJECTS_PER_PAGE } from '../model/constants';
import { baseApi } from 'shared/api/baseApi';
import { APP_PROJECTS_TAG, APP_TAG, PROJECTS_LIST_TAG } from 'shared/api/tags';
import qs from 'qs';
import { TApplication, TArtefact, TProjectDTO, TProjectsDTO } from './types';

export const projectApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getProjects: build.query<
			TProjectsDTO,
			| {
					search?: string;
					page: number;
			  }
			| undefined
		>({
			query: (params) => {
				if (!params) {
					return {
						url: `projects`
					};
				}
				let query;
				if (params.search) {
					query = {
						name: {
							contains: params.search
						}
					};
				}

				const stringifiedQuery = qs.stringify(
					{
						...(query ? { where: query } : {}),
						limit: PROJECTS_PER_PAGE,
						page: params.page
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `projects${stringifiedQuery}`
				};
			},
			transformResponse: (response: Omit<TProjectsDTO, 'application'> & { application?: TApplication | string }) => {
				const normalizedProjects = response.docs.map((project) => {
					return {
						...project,
						application: typeof project.application === 'string' ? { id: project.application } : project.application
					};
				});
				return { ...response, docs: normalizedProjects };
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			merge: (currentCache, newItems, { arg }) => {
				if (arg && arg.page !== 1) {
					return {
						...currentCache,
						...newItems,
						docs: [...currentCache.docs, ...newItems.docs]
					};
					currentCache.docs.push(...newItems.docs);
				}
				return newItems;
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: [PROJECTS_LIST_TAG]
		}),
		getAppProjects: build.query<TProjectsDTO, string>({
			query: (id) => {
				const stringifiedQuery = qs.stringify(
					{
						depth: 0,
						where: {
							application: {
								equals: id
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `projects${stringifiedQuery}`
				};
			},
			providesTags: [APP_PROJECTS_TAG]
		}),
		deleteProject: build.mutation<TProjectDTO, string>({
			query: (id) => {
				const stringifiedQuery = qs.stringify(
					{
						depth: 0,
						where: {
							id: {
								equals: id
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `projects${stringifiedQuery}`,
					method: 'DELETE'
				};
			},
			invalidatesTags: [PROJECTS_LIST_TAG, APP_PROJECTS_TAG]
		}),
		createProject: build.mutation<TProjectDTO, { idea: string; applicationId: string; isDemo?: boolean }>({
			query: ({ idea, applicationId, isDemo }) => {
				return {
					url: `projects/`,
					method: 'POST',
					data: {
						name: idea,
						idea,
						application: applicationId,
						...(isDemo ? { isDemo } : {})
					}
				};
			},
			transformResponse: (response: { doc: TProjectDTO }) => {
				return response.doc;
			},
			invalidatesTags: [PROJECTS_LIST_TAG, APP_PROJECTS_TAG, APP_TAG]
		}),
		generateArtefact: build.mutation<TArtefact<any>[], { projectId: string; hasBlocks: boolean }>({
			query: ({ projectId, hasBlocks }) => {
				return {
					url: `projects/${projectId}/generate${hasBlocks ? 'Blocks' : ''}`,
					method: 'POST'
				};
			},
			invalidatesTags: [PROJECTS_LIST_TAG, APP_PROJECTS_TAG, APP_TAG]
		})
	})
});

export const {
	useGetProjectsQuery,
	useDeleteProjectMutation,
	useCreateProjectMutation,
	useGetAppProjectsQuery,
	useGenerateArtefactMutation
} = projectApi;
