import React, { memo, useCallback, useRef } from 'react';
import ReactFlow, {
	addEdge,
	Background,
	BackgroundVariant,
	OnConnectStart,
	OnConnectEnd,
	useReactFlow,
	Connection,
	NodeMouseHandler,
	Node,
	EdgeChange,
	applyEdgeChanges,
	NodeChange,
	applyNodeChanges,
	MarkerType
} from 'reactflow';
import { GptCallAction } from '../GptCallAction';
import { generateId } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { selectEdges, selectActions } from '../../model/selectors';
import { setCurrentTab, setEdges, setActions, updateActionData } from '../../model/slice';
import { IdeaAction } from '../IdeaAction';
import { InitialNode } from '../InitialNode';
import { ApiCallAction } from '../ApiCallAction';
import { FormInstance } from 'antd';
import { STYLES } from 'shared';
import { CustomEdge } from '../CustomEdge';
import { JsonataCallAction } from '../JsonataCallAction';
import { LlmCallAction } from '../LlmCallAction';
import { MailingCallAction } from '../MailingCallAction';
import { VaultCallAction } from '../VaultCallAction';
import { TaskCallAction } from '../TaskCallAction';
import { ApiTemplateCallAction } from '../ApiTemplateCallAction';

const nodeTypes = {
	gpt_call: GptCallAction,
	llm_call: LlmCallAction,
	idea: IdeaAction,
	initial: InitialNode,
	api_call: ApiCallAction,
	api_template_call: ApiTemplateCallAction,
	jsonata_call: JsonataCallAction,
	mailing_call: MailingCallAction,
	vault_call: VaultCallAction,
	task_call: TaskCallAction
};

interface IAppFlowEditor {
	form: FormInstance;
}

const AppFlowEditorComponent = ({ form }: IAppFlowEditor) => {
	const dispatch = useAppDispatch();
	const connectingNodeId = useRef<string | null>(null);
	const actions = useAppSelector(selectActions);
	const edges = useAppSelector(selectEdges);
	const { screenToFlowPosition } = useReactFlow();

	const edgeTypes = {
		customedge: CustomEdge
	};

	const onConnect = useCallback((connection: Connection) => {
		connectingNodeId.current = null;
		dispatch(setEdges(addEdge(connection, edges)));
	}, []);

	const onEdgesChange = (changes: EdgeChange[]) => {
		if (changes[0]?.type && ['select', 'remove'].includes(changes[0]?.type)) return;
		const newEdges = applyEdgeChanges(changes, edges);
		dispatch(setEdges(newEdges));
	};

	const onNodesChange = (changes: NodeChange[]) => {
		if (changes.find((node) => node.type !== 'remove')) {
			const newNodes = applyNodeChanges(changes, actions);
			dispatch(setActions(newNodes));
		}
	};

	const onConnectStart: OnConnectStart = useCallback((_, { nodeId }) => {
		connectingNodeId.current = nodeId;
	}, []);

	const onConnectEnd: OnConnectEnd = useCallback(
		(event) => {
			const actionId = connectingNodeId.current;
			if (!(event instanceof MouseEvent) || !actionId) return;
			const sourceIndex = actions?.findIndex((action) => action.id === actionId);
			const targetIsPane = (event.target as HTMLElement)?.classList.contains('react-flow__pane');
			if (targetIsPane) {
				const id = `${generateId().substring(0, 5)}`;
				const newAction: Node = {
					id,
					type: 'initial',
					selected: true,
					position: screenToFlowPosition({
						x: event.clientX,
						y: event.clientY
					}),
					data: { slug: id, type: 'static_text' }
				};
				const newActions = actions.map((action) => ({ ...action, selected: false }));
				dispatch(setActions([...newActions, newAction]));
				dispatch(
					setEdges(
						edges.concat([
							{
								id: `${actionId}->${id}`,
								source: actionId,
								target: id,
								type: 'customedge',
								markerEnd: {
									type: MarkerType.ArrowClosed,
									color: STYLES.colors.gray_light
								},
								style: {
									stroke: STYLES.colors.gray_light,
									strokeWidth: 2
								}
							}
						])
					)
				);
				dispatch(updateActionData({ actionId, path: ['actions', sourceIndex, 'nextSlug'].join('.'), value: id }));
				form.setFieldValue(['actions', sourceIndex, 'nextSlug'], id);
			}
		},
		[screenToFlowPosition, actions, edges, connectingNodeId.current]
	);

	const onNodeClick: NodeMouseHandler = useCallback((event, node) => {
		dispatch(setCurrentTab('steps'));
	}, []);

	return (
		<>
			<ReactFlow
				nodes={actions}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				elementsSelectable={true}
				onConnect={onConnect}
				onNodeClick={onNodeClick}
				onConnectStart={onConnectStart}
				onConnectEnd={onConnectEnd}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				multiSelectionKeyCode={null}
				selectionKeyCode={null}
				fitView
				proOptions={{ hideAttribution: true }}
			>
				<Background variant={BackgroundVariant.Dots} gap={6} size={0.5} offset={1} style={{ opacity: '0.15' }} />
			</ReactFlow>
		</>
	);
};

export const AppFlowEditor = memo(AppFlowEditorComponent);
