import { baseApi } from 'shared/api/baseApi';
import { USER_TAG } from 'shared/api/tags';
import { TUser } from '../model/types';
import qs from 'qs';

export const userApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getUser: build.query<{ user: TUser }, void>({
			query: () => ({
				url: `users/me`
			}),
			providesTags: [USER_TAG]
		}),
		changePassword: build.mutation<
			any,
			{
				oldPassword: string;
				newPassword: string;
			}
		>({
			query: ({ oldPassword, newPassword }) => {
				return {
					url: 'users/me/change-password',
					method: 'POST',
					data: { oldPassword, newPassword }
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		forgotPassword: build.mutation<
			any,
			{
				email: string;
			}
		>({
			query: ({ email }) => {
				return {
					url: 'users/forgot-password',
					method: 'POST',
					data: { email }
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		resetPassword: build.mutation<
			any,
			{
				token: string;
				password: string;
			}
		>({
			query: ({ token, password }) => {
				return {
					url: 'users/reset-password',
					method: 'POST',
					data: { token, password }
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		loginUser: build.mutation<
			{ user: TUser },
			{
				email: string;
				password: string;
			}
		>({
			query: ({ email, password }) => {
				return {
					url: 'users/login',
					method: 'POST',
					data: {
						email,
						password
					}
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		updateDefaultThreadModel: build.mutation<{ user: TUser }, { modelId: string; userId: string }>({
			query: ({ modelId, userId }) => {
				return {
					url: `users/${userId}`,
					method: 'PATCH',
					data: {
						defaultThreadModel: modelId
					}
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		setOnboarded: build.mutation<{ user: TUser }, { pageName: string; userId: string }>({
			query: ({ pageName, userId }) => {
				return {
					url: `users/${userId}`,
					method: 'PATCH',
					data: {
						onboarded: { [pageName]: true }
					}
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		signupUser: build.mutation<
			{ user: TUser },
			{ email: string; password: string; policy: boolean; phone: string; promoCode?: string }
		>({
			query: ({ email, password, policy, phone, promoCode }) => {
				return {
					url: 'users/register',
					method: 'POST',
					data: {
						email,
						password,
						policy,
						phone,
						...(promoCode ? { promoCode } : {})
					}
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		signupDemoUser: build.mutation<{ user: TUser }, void>({
			query: () => {
				return {
					url: 'users/register-demo',
					method: 'POST'
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		logoutUser: build.mutation<any, void>({
			query: () => {
				return {
					url: 'users/logout',
					method: 'POST'
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		uploadAvatar: build.mutation<{ doc: { id: string; url: string } }, { formData: FormData }>({
			query: ({ formData }) => {
				return {
					url: `users_avatars`,
					method: 'POST',
					data: formData
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		deleteAvatar: build.mutation<any, { fileId: string }>({
			query: ({ fileId }) => {
				const stringifiedQuery = qs.stringify(
					{
						where: {
							id: {
								equals: fileId
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `users_avatars${stringifiedQuery}`,
					method: 'DELETE'
				};
			}
		}),
		updateFirstAndMiddleName: build.mutation<
			any,
			{
				firstName: string;
				lastName: string;
				userId: string;
			}
		>({
			query: ({ firstName, lastName, userId }) => {
				return {
					url: `users/${userId}`,
					method: 'PATCH',
					data: { firstName, lastName }
				};
			},
			invalidatesTags: [USER_TAG]
		}),
		setLocale: build.mutation<
			void,
			{
				locale: string;
			}
		>({
			query: ({ locale }) => {
				return {
					url: 'payload-preferences/locale',
					method: 'POST',
					data: { value: locale }
				};
			},
			invalidatesTags: [USER_TAG]
		})
	})
});

export const {
	useGetUserQuery,
	useLazyGetUserQuery,
	useLogoutUserMutation,
	useLoginUserMutation,
	useSignupUserMutation,
	useSignupDemoUserMutation,
	useSetOnboardedMutation,
	useUpdateDefaultThreadModelMutation,
	useChangePasswordMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
	useUploadAvatarMutation,
	useUpdateFirstAndMiddleNameMutation,
	useDeleteAvatarMutation,
	useSetLocaleMutation
} = userApi;
