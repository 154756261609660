import React, { memo, useMemo, useState } from 'react';
import { Button, Form, FormInstance, Input, Select, Switch } from 'antd';
import { EAppFormFields } from '../../model/types';
import { CustomPopover } from 'shared/ui';
import { SmallLogoIcon } from 'shared/ui/Icons';
import cx from '../EditAppForm/EditAppForm.module.css';
import { TAGS_MAX_COUNT } from '../../model/constants';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { updateEmoji } from '../../model/slice';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { useTranslate } from 'shared/lib/i18n';
import { useGetTagsQuery } from 'entities/apps';
import { selectAppSettings } from '../../model/selectors';

interface IAppSettingsFormItems {
	form: FormInstance;
	isActive: boolean;
}

const AppSettingsFormItemsComponent = ({ isActive, form }: IAppSettingsFormItems) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const { name, description, tags, isPrivate, emoji, descriptionSmall } = useAppSelector(selectAppSettings);
	const [openPicker, setOpenPicker] = useState(false);
	const formTags = Form.useWatch('tags', form);

	const { tagList } = useGetTagsQuery(undefined, {
		selectFromResult: ({ data }) => {
			return { tagList: data?.docs.map((tag) => ({ label: tag.name, value: tag.id })) ?? [] };
		}
	});

	const emojiPickerContent = useMemo(() => {
		return (
			<div>
				<Picker
					data={data}
					onEmojiSelect={(emoji: any) => {
						if ('native' in emoji) {
							dispatch(updateEmoji(emoji.native));
							form.setFieldValue(EAppFormFields.EMOJI, emoji.native);
							setOpenPicker(false);
						}
					}}
					theme='light'
				/>
				<Button
					type='text'
					onClick={() => {
						dispatch(updateEmoji(''));
						form.setFieldValue(EAppFormFields.EMOJI, '');
						setOpenPicker(false);
					}}
					className='mt-2'
				>
					{t('default')}
				</Button>
			</div>
		);
	}, [data, emoji]);

	return (
		<div className={isActive ? '' : 'hidden'}>
			<Form.Item
				label={<span className='text-sm font-medium mb-2'>{t('icon')}</span>}
				name={EAppFormFields.EMOJI}
				initialValue={emoji}
				className='!mb-3'
			>
				<CustomPopover
					destroyTooltipOnHide={true}
					trigger='click'
					open={openPicker}
					content={emojiPickerContent}
					onOpenChange={(newOpen) => setOpenPicker(newOpen)}
					arrow={false}
				>
					<div className='w-[48px] h-[48px] min-w-[48px] max-h-[48px] text-4xl rounded-md hover:bg-gray-bg-hover flex items-center justify-center overflow-hidden cursor-pointer'>
						{emoji ? (
							<span>{emoji}</span>
						) : (
							<span className='text-primary-active'>
								<SmallLogoIcon />
							</span>
						)}
					</div>
				</CustomPopover>
			</Form.Item>
			<Form.Item
				label={
					<span className='text-sm font-medium'>
						{t('title')}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={EAppFormFields.NAME}
				initialValue={name}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 3, message: `${t('error.min_length')} 3` },
					{
						max: 200,
						message: `${t('error.max_length')} 200`
					},
					{
						validator: (_: any, value: string) => {
							if (value && value.trim() === '') {
								return Promise.reject(new Error(t('error.cannot_only_spaces')));
							}
							return Promise.resolve();
						}
					}
				]}
				className={`${cx.text}`}
			>
				<Input
					count={{
						show: ({ value, count, maxLength }) => (
							<span className='absolute right-0 bottom-[100%] pb-2 text-xs'>
								{count}/{maxLength}
							</span>
						),
						max: 200
					}}
				/>
			</Form.Item>
			<Form.Item
				label={
					<span className='text-sm font-medium'>
						{t('description_small')} <span className='ml-1 text-error'>*</span>
					</span>
				}
				name={EAppFormFields.DESCRIPTION_SMALL}
				className={cx.text}
				initialValue={descriptionSmall}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 3, message: `${t('error.min_length')} 3` },
					{
						max: 200,
						message: `${t('error.max_length')} 200`
					}
				]}
			>
				<Input.TextArea
					rows={2}
					count={{
						show: ({ value, count, maxLength }) => (
							<span className='absolute right-0 bottom-[100%] pb-2 text-xs'>
								{count}/{maxLength}
							</span>
						),
						max: 200
					}}
				/>
			</Form.Item>
			<Form.Item
				label={<span className='text-sm font-medium'>{t('description')}</span>}
				name={EAppFormFields.DESCRIPTION}
				className={cx.text}
				initialValue={description}
				rules={[
					{ min: 3, message: `${t('error.min_length')} 3` },
					{
						max: 200,
						message: `${t('error.max_length')} 200`
					}
				]}
			>
				<Input.TextArea rows={2} />
			</Form.Item>
			<div className={cx.text}>
				<span className='absolute right-0 top-0 pt-2 text-xs opacity-50'>
					{formTags?.length || 0}/{TAGS_MAX_COUNT}
				</span>
				<Form.Item
					label={<span className='text-sm font-medium'>{t('categories')}</span>}
					name={EAppFormFields.TAGS}
					initialValue={tags}
				>
					<Select
						mode='multiple'
						allowClear
						maxCount={TAGS_MAX_COUNT}
						style={{ width: '100%' }}
						options={tagList}
					></Select>
				</Form.Item>
			</div>
			<Form.Item label='Приватный' name={EAppFormFields.IS_PRIVATE} initialValue={isPrivate} rules={[]} hidden={true}>
				<Switch />
			</Form.Item>
		</div>
	);
};

export const AppSettingsFormItems = memo(AppSettingsFormItemsComponent);
